<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="物品类型:">
          <el-select 
						v-model="searchForm.type" 
						placeholder="请选择" 
						@keyup.enter.native="seach"
						@visible-change="visibleType"
						ref="selectref"
						clearable>
            <el-option
              v-for="item in typeSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物品名称:">
          <el-input 
						v-model.trim="searchForm.name" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="所属教学点名称:">
          <el-input 
						v-model.trim="searchForm.schoolorgname"
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        label="物品名称"
        prop="name"
        align="center"
        width="100"
      >
      <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.name
          }}</el-button>
        </template>
      </el-table-column>
       <el-table-column
        label="物品类型"
        prop="type"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="物品单位"
        prop="unit"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="库存原数量"
        prop="originalnum"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="入库数量"
        prop="warehousingnum"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        label="剩余数量"
        prop="surplusnum"
        align="center"
        width="100"
      ></el-table-column>

      <el-table-column label="入库时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template></el-table-column
      >

      <el-table-column
        label="教学点"
        prop="schoolorgname"
        align="center"
        width="130"
      ></el-table-column>
      <el-table-column
        label="分公司"
        prop="companyorgname"
        align="center"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <Export
      :exportTitle="'入库记录'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
			:close-on-click-modal='false'
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <table border="1" cellpadding="10" cellspacing="0" class="base_table" >
        <tr>
          <td>库存原数量：{{ dynamicValidateForm.originalnum }}</td>
          <td>入库数量：{{ dynamicValidateForm.warehousingnum }}</td>
          <td>剩余数量：{{ dynamicValidateForm.surplusnum }}</td>
        </tr>
        <tr>
          <td>录入时间：{{ dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
          <td>物品类型：{{ dynamicValidateForm.type }}</td>
          <td>物品名称：{{ dynamicValidateForm.name }}</td>
        </tr>
        <tr>
          <td>物品单位：{{ dynamicValidateForm.unit }}</td>
          <td>所属教学点名称：{{ dynamicValidateForm.schoolorgname }}</td>
          <td>所属分公司名称：{{ dynamicValidateForm.companyorgname }}</td>
        </tr>
        <tr>
          <td>录入人：{{ dynamicValidateForm.insertusername }}</td>
					<td>流水号： {{dynamicValidateForm.id}}</td>
					<td></td>
        </tr>
        
      </table>
    </el-dialog>
  </div>
</template>
<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import {
  warehousing_list,
  warehousing_export,
  warehousing_exportexcel,
  warehousing_exportsave,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "warehousing",
  components: {
    Export,
  },
  props: {
    exportUrl: {
      default: () => warehousing_export,
    },
    exportexcelUrl: {
      default: () => warehousing_exportexcel,
    },
    exportsaveUrl: {
      default: () => warehousing_exportsave,
    },
  },
  data() {
    return {
      transferDialog: false, //导出框
      itemId: null, //每一项id
      searchForm: {
        id: "",
        type: "",
        name: "",
        schoolorgname: "",
      },
      dynamicValidateForm:{},
      typeSelect: [
        {
          value: "教材",
          label: "教材",
        },
        {
          value: "耐用品",
          label: "耐用品",
        },
        {
          value: "易耗品",
          label: "易耗品",
        },
      ], //物品类型
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      multipleSelection: [], //选中的表格项
      showDetailModal: false,

    };
  },
  created() {
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				type: null,
				name: null,
				schoolorgname: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: warehousing_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //刷新 click
    btnRefresh() {
      this.getDataList();
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>